<template>
  <div class="side-navBar" style="position: relative">
    <HomePageTop/>
    <header>
      <div class="headerBanner" style="margin-top: -32px">
        <div class="headerBannerText">{{ $fanyi("我们为您管理一切国际物流") }}</div>
        <div class="headerBannerBottomText">
          <div>{{ $fanyi("Rakumart为您管理和执行从中国进口的整个物流流程。") }}</div>
          <div>{{
              $fanyi("利用Rakumart提供的所有资源，彻底改变您从中国购买和进口的方式，为您的业务节省时间和成本。")
            }}
          </div>
        </div>
      </div>
      <div class="logisticsAcceptancePeriod" id="logisticsAcceptancePeriod">
        <div class="logisticsAcceptancePeriodTitle">{{ $fanyi("物流纳期一览") }}</div>
        <div class="logisticsAcceptancePeriodTableContainer">
          <div class="logisticsAcceptancePeriodTableHeader flexAndCenter">
            <div>{{ $fanyi("物流名称") }}</div>
            <div>{{ $fanyi("纳期") }}</div>
          </div>
          <div class="logisticsAcceptancePeriodTableFooter">
            <div v-for="(item,index) in logisticsAcceptancePeriodTable" :key="index"
                 class="logisticsAcceptancePeriodTableRow flexAndCenter">
              <div v-if="item.is_show===true" style="max-width: 300px">{{ item.name }}</div>
              <div v-if="item.is_show===true&&item.useful_rule!=null">
                {{ item.useful_rule.detail.sign_receive_days != 0 ? item.useful_rule.detail.sign_receive_days : '' }}
              </div>
            </div>
          </div>
        </div>
        <div class="leftCatalogueContainer" v-show="showStatus===true">
          <div v-for="(item,index) in catalogueList" :key="index"
               :class="catalogueIndex===index?'catalogueContainer checkedCatalogue':'catalogueContainer'"
               @click="goAuchor(item.id);catalogueIndex=index">
            {{ $fanyi(item.name) }}
          </div>
        </div>
      </div>
      <div class="internationalLogisticsFreightEstimationContainer"
           id="internationalLogisticsFreightEstimationContainer">
        <div class="logisticsAcceptancePeriodTitle">{{ $fanyi("国际物流运费预估") }}</div>
        <div class="boxListContainer">
          <div class="boxListHeader">
            <div class="No">{{ $fanyi('箱号') }}</div>
            <div class="kg">{{ $fanyi('计费重量') }}</div>
            <div class="kg">{{ $fanyi('长') }}</div>
            <div class="kg">{{ $fanyi('宽') }}</div>
            <div class="kg">{{ $fanyi('高') }}</div>
            <div class="kg">{{ $fanyi('计费体积') }}</div>
            <div class="btn" @click="addBox">{{ $fanyi('新增箱子') }}</div>
          </div>
          <div class="boxListFooter">
            <div v-for="(item,index) in boxList" :key="index" :style="index===boxList.length-1?'margin-bottom:0':''"
                 class="boxInfoContainer">
              <div class="No">{{ index + 1 }}</div>
              <div class="kg">
                <div class="inputContainer" style="margin-right:20px">
                  <el-input v-model.number="item.kg" :placeholder="$fanyi('计费重量')" type="number" @blur="isNull"/>
                  <div class="inputText">kg</div>
                </div>
              </div>
              <div class="kg">
                <div class="inputContainer">
                  <el-input v-model.number="item.long" :placeholder="$fanyi('长')" type="number" @blur="isNull"/>
                  <div class="inputText">cm</div>
                </div>
                <div class="symbol">x</div>
              </div>
              <div class="kg">
                <div class="inputContainer">
                  <el-input v-model.number="item.width" :placeholder="$fanyi('宽')" type="number" @blur="isNull"/>
                  <div class="inputText">cm</div>
                </div>
                <div class="symbol">x</div>
              </div>
              <div class="kg">
                <div class="inputContainer">
                  <el-input v-model.number="item.height" :placeholder="$fanyi('高')" type="number" @blur="isNull"/>
                  <div class="inputText">cm</div>
                </div>
                <div class="symbol" style="margin-left: 7px">=</div>
              </div>
              <div class="kg">
                <div class="inputContainer">
                  <el-input v-model.number="item.stere" disabled/>
                  <div class="inputText">m³</div>
                </div>
              </div>
              <div class="btn" style="background: #B4272B;line-height: 50px" @click="deleteBox(index)">{{
                  $fanyi('删除')
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="internationalLogisticsFreightListContainer">
          <div v-for="(item,index) in logisticsAmountAllTable" :key="index"
               class="internationalLogisticsFreightContainer">
            <img src="../../assets/boxIcon.png">
            <div class="internationalLogisticsName">{{ item.logistics_name }}</div>
            <div class="internationalLogisticsKg">決済重量{{ item.charged_weight }}kg</div>
            <div class="colorB4272B" style="margin-bottom: 10px;">{{ item.total_money_rmb }}{{ $fanyi("元") }}</div>
            <div class="colorB4272B">
              {{
                $fun.yenNumSegmentation(Number(Number(item.total_money_rmb) * Number(exchangeRate)).toFixed(0))
              }}{{ $fanyi("円") }}
            </div>
          </div>
        </div>
      </div>
    </header>
    <section>
      <div class="internationalLogisticsSimpleComparisonContainer" id="internationalLogisticsSimpleComparisonContainer">
        <div class="logisticsAcceptancePeriodTitle">{{ $fanyi("国际物流简易比较") }}</div>
        <div class="internationalLogisticsSimpleComparisonTableHeader flexAndCenter">
          <div class="left">
            <div class="leftHeaderText">航空便</div>
            <div class="flexAndCenter">
              <div>EMS</div>
              <div>OCS</div>
              <div>KS-JP</div>
              <div>電子特別便</div>
              <div>DHL</div>
            </div>
          </div>
          <div class="right">
            <div class="rightHeaderText">船 便</div>
            <div class="flexAndCenter">
              <div>EMS船便</div>
              <div>DQ船便</div>
              <div>TW船便</div>
              <div>RW船便</div>
              <div>FBA新幹線</div>
              <div>コンテナ</div>
            </div>
          </div>
        </div>
        <div class="internationalLogisticsSimpleComparisonTableFooter flex">
          <div class="leftContainer">
            <div>FBA納品</div>
            <div>電池・磁石</div>
            <div>液体 含む</div>
            <div>重量制限</div>
            <div>同梱制限</div>
            <div>納期(目安)</div>
            <div>配送会社</div>
            <div>容積換算値</div>
            <div>最安価格</div>
            <div style="height: 141px;line-height: 140px">備考</div>
          </div>
          <div class="rightContainer">
            <!--            FBA納品-->
            <div class="flexAndCenter">
              <div>X</div>
              <div>O</div>
              <div>O</div>
              <div>O</div>
              <div>O</div>
              <div>X</div>
              <div>O</div>
              <div>O</div>
              <div>O</div>
              <div>O</div>
              <div>O</div>
            </div>
            <!--            電池・磁石-->
            <div class="flexAndCenter">
              <div>X</div>
              <div>X</div>
              <div>X</div>
              <div>O</div>
              <div>X</div>
              <div>O</div>
              <div>O</div>
              <div>磁石〇電池×</div>
              <div>磁石〇電池×</div>
              <div>O</div>
              <div>O</div>
            </div>
            <!--            液体 含む-->
            <div class="flexAndCenter">
              <div>X</div>
              <div>X</div>
              <div>X</div>
              <div>X</div>
              <div>X</div>
              <div>O</div>
              <div>X</div>
              <div>X</div>
              <div>X</div>
              <div>X</div>
              <div>O</div>
            </div>
            <!--            重量制限-->
            <div class="flexAndCenter">
              <div>～30KG/箱</div>
              <div>無制限</div>
              <div>無制限</div>
              <div>～30KG/箱</div>
              <div>～30KG/箱</div>
              <div>～30KG/箱</div>
              <div>無制限</div>
              <div>無制限</div>
              <div>無制限</div>
              <div>無制限</div>
              <div>/</div>
            </div>
            <!--            同梱制限-->
            <div class="flexAndCenter">
              <div>無</div>
              <div>無</div>
              <div>無</div>
              <div>無</div>
              <div>無</div>
              <div>無</div>
              <div>無</div>
              <div>無</div>
              <div>無</div>
              <div>無</div>
              <div>なし</div>
            </div>
            <!--            納期(目安)-->
            <div class="flexAndCenter">
              <div>4日～</div>
              <div>3日～</div>
              <div>3日～</div>
              <div>5日～</div>
              <div>2～3日</div>
              <div>3週間～</div>
              <div>9日～</div>
              <div>9日～</div>
              <div>9日～12日</div>
              <div>15日～18日</div>
              <div>10日～</div>
            </div>
            <!--            配送会社-->
            <div class="flexAndCenter">
              <div>日本郵便</div>
              <div>佐川急便</div>
              <div>佐川急便</div>
              <div>佐川急便</div>
              <div>自社配送・その他</div>
              <div>日本郵便</div>
              <div>佐川急便・その他</div>
              <div>佐川急便・その他</div>
              <div>佐川急便・その他</div>
              <div>佐川急便・その他</div>
              <div>/</div>
            </div>
            <!--            容積換算値-->
            <div class="flexAndCenter">
              <div>6000</div>
              <div>6000</div>
              <div>6000</div>
              <div>6000</div>
              <div>5000</div>
              <div>6000</div>
              <div>6000</div>
              <div>6000</div>
              <div>6000</div>
              <div>6000</div>
              <div>/</div>
            </div>
            <!--            最安価格-->
            <div class="flexAndCenter">
              <div>下記参照</div>
              <div>17元/KG</div>
              <div>16元/KG</div>
              <div>33元/KG</div>
              <div>/</div>
              <div>下記参照</div>
              <div>6.5元/KG</div>
              <div>6.5元/KG</div>
              <div>6.3元/KG</div>
              <div>1450/m³</div>
              <div>/</div>
            </div>
            <!--            備考-->
            <div class="flexAndCenter remark">
              <div style="line-height: 28px">月曜日～金曜日のみ運行<br> 箱当たり4元の通関手数料が別途請求（中国側）</div>
              <div>沖縄 33kg以下＋100元、<br>33kg以上 +3元/kg</div>
              <div style="line-height: 24px">沖縄・北海道・鹿児島<br>遠隔地費用あり<br>通関手数料 1500円/回<br>（税込）
              </div>
              <div>沖縄・北海道・鹿児島<br>遠隔地費用あり</div>
              <div>離島地域は別途追加費用があり</div>
              <div>箱あたり4元の通関手数料が別途請求（中国側）</div>
              <div>週3回の集荷発送、遠隔地費用あり</div>
              <div>週3回の集荷発送、遠隔地費用あり</div>
              <div>定額会員限定利用、週3回の集荷発送、遠隔地費用あり</div>
              <div>AmazonFBA納品専用、週2回の集荷発送</div>
              <div>/</div>
            </div>
          </div>
        </div>
        <div class="internationalLogisticsSimpleComparisonRedText">
          <div>※運賃は下記の送料表をご覧の上、配送方法をお選び下さい。</div>
          <div>※各運送会社の追跡サイトは弊社トップページに記載されます。</div>
          <div>※最安価格は発送重量によって変わりますので、あくまで参考（最安値）となります。</div>
          <div>※その他、コンテナ混載便や貸切コンテナ便もあります。詳しく下記でご確認下さい。</div>
          <div>※現在基本日本向けの配送になります、その他の国へ配送されたい場合は個別で担当者まで問い合わせください。</div>
        </div>
        <div class="newTitle">商品発送の注意事項</div>
        <div class="internationalLogisticsSimpleComparisonFooterText">
          <div>※特に指定が無い場合は、20kg以上はOCSにて発送させて頂きます。</div>
          <div>※全配送会社、実際重量と容積換算どちらのか重い方の計算となります。</div>
          <div>※容積換算重量算出の場合は、いずれも計算値6000で計算致します。（高速船便、FedEx　5000　OCS 6000で計算）</div>
          <div>容積換算式：段ボール箱外寸（60cm×40cm×50cm）、容積重量は（60×40×50）÷定数（6000の場合）＝20KGになります。</div>
          <div>
            ※関税、輸入消費税が発生する場合があります。金額は日本税関を通過するまで解りません。関税が発生した場合、着払い又は後日請求になります。
          </div>
        </div>
      </div>
    </section>
    <footer>
      <div class="logisticsAcceptancePeriodTitle">{{ $fanyi("物流收费说明") }}</div>
      <div class="internationalLogisticsFooterContainer">
        <div v-for="(item,index) in logisticsAcceptancePeriodTable" :key="index"
             class="internationalLogisticsMannerContainer" :id="'internationalLogisticsManner'+(index+1)">
          <div class="internationalLogisticsMannerContainerTop flexAndCenter">
            <div>RAKUMART - JP</div>
            <div>{{ index < 10 ? '0' + (index + 1) : index + 1 }}</div>
          </div>
          <div class="internationalLogisticsName">{{ item.name }}</div>
          <div v-html="item.tableData" class="tableDataClass"></div>
          <div class="mattersNeedAttentionTitleText">注意事項</div>
          <div class="mattersNeedAttentionExplainText" v-if="item.useful_rule!=null">
            <div v-html="item.useful_rule.note_jp"></div>
          </div>
        </div>
      </div>
    </footer>
    <div class="hideOperationContainer" @click="showStatus = !showStatus">
      {{ showStatus === true ? `${$fanyi(`隐藏`)} >` : `< ${$fanyi(`展开`)}` }}
    </div>
    <div id="sendToEurope" style="margin-bottom: 30px">
      <div class="sendToEuropeList">
        <div class="sendToEuropeTitle">ヨーロッパへ発送</div>
        <div class="internationalLogisticsSimpleComparisonTableHeader flexAndCenter">
          <div class="left">
            <div class="leftHeaderText">最低発送重量</div>
          </div>
          <div class="right">
            <div class="rightHeaderText">納期（目安）</div>
          </div>
        </div>
        <div class="internationalLogisticsSimpleComparisonTableFooter flex">
          <div class="leftContainer">
            <div>航空便</div>
            <div>トラック</div>
            <div>船便</div>
            <div>鉄道</div>
          </div>
          <div class="rightContainer">
            <!--            航空便-->
            <div class="flexAndCenter">
              <div>なし</div>
              <div>10～15日</div>
            </div>
            <!--            トラック-->
            <div class="flexAndCenter">
              <div>21キロ</div>
              <div>25～30日</div>
            </div>
            <!--            船便-->
            <div class="flexAndCenter">
              <div>1m³</div>
              <div>45～50日</div>
            </div>
            <!--            鉄道-->
            <div class="flexAndCenter">
              <div>21キロ</div>
              <div>50～60日</div>
            </div>
          </div>
        </div>
        <div class="sendToEuropeUpdateDate">※ 最終更新日: 2023.10.16</div>
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 18px;">注意項目</div>
        <div style="color: rgb(230, 0, 0);line-height: 24px">
          ヨーロッパ各国への発送について<br>
          国別で納期や金額が違うため、<br>
          ご利用を希望の際は発送予定の商品内容を担当者へ共有していただければ、<br>
          個別で見積させていただきます。
        </div>
      </div>
      <div class="sendToEuropeList">
        <div class="sendToEuropeTitle">アメリカへ発送</div>
        <div class="internationalLogisticsSimpleComparisonTableHeader flexAndCenter">
          <div class="left">
            <div class="leftHeaderText">最低発送重量</div>
          </div>
          <div class="right">
            <div class="rightHeaderText">納期（目安）</div>
          </div>
        </div>
        <div class="internationalLogisticsSimpleComparisonTableFooter flex">
          <div class="leftContainer">
            <div>航空便</div>
            <div>マトソン</div>
            <div>FEDEX</div>
          </div>
          <div class="rightContainer">
            <!--            航空便-->
            <div class="flexAndCenter">
              <div>12キロ</div>
              <div>9～15日</div>
            </div>
            <!--            マトソン-->
            <div class="flexAndCenter">
              <div>21キロ</div>
              <div>23～30日</div>
            </div>
            <!--            FEDEX-->
            <div class="flexAndCenter">
              <div>0.5キロ</div>
              <div>7～10日</div>
            </div>
          </div>
        </div>
        <div class="sendToEuropeUpdateDate">※ 最終更新日: 2023.11.3</div>
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 18px;">注意項目</div>
        <div style="color: rgb(230, 0, 0);line-height: 24px">
          アメリカへの発送について<br>
          地域（東部、中部、西部）や商品の種類によって、国際運賃と納期が異なりますので、<br>
          ご利用を希望の際は発送予定の商品内容を担当者へ共有していただければ、<br>
          個別で見積させていただきます。
        </div>
      </div>
    </div>
    <FootVue/>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import FootVue from "../../components/foot/Foot.vue";

export default {
  components: {
    HomePageTop,
    FootVue
  },
  data() {
    return {
      clientWidth: 0,
      kg: 0,
      long: 0,
      width: 0,
      height: 0,
      stere: 0,
      boxList: [{
        kg: 0,
        long: 58,
        width: 51,
        height: 50,
        stere: 0,
        newStere: 0
      }],
      newStere: 0,
      catalogueIndex: 0,
      catalogueList: [{
        id: 'logisticsAcceptancePeriod',
        name: '物流纳期一览'
      }, {
        id: 'internationalLogisticsFreightEstimationContainer',
        name: '国际物流运费预估'
      }, {
        id: 'internationalLogisticsSimpleComparisonContainer',
        name: '簡易比較'
      }],
      showStatus: true,
      timer: null,
      ContentHeightList: [],
      logisticsAmountAllTable: [],
      logisticsAcceptancePeriodTable: [{
        useful_rule: {}
      }]
    }
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  mounted() {
    this.clientWidth = document.body.clientWidth;
    window.addEventListener("scroll", this.handleScroll, true);
    // 获得内容高度
    this.$api.logisticsTableData().then((res) => {
      res.data.forEach((item) => {
        if (item.name == 'RW船便' || item.name == 'Raku-DQ') {
          item.name += '（定額会員限定利用）'
        }
      })
      this.logisticsAcceptancePeriodTable = res.data;
      this.logisticsAmountAllTable = this.$fun.deepClone(res.data);
      this.logisticsAmountAllTable.forEach((item, index) => {
        item.logistics_name = item.name;
        item.total_money_rmb = 0;
        item.charged_weight = 0;
        this.catalogueList.push({id: 'internationalLogisticsManner' + (index + 1), name: item.name})
        if (this.$route.query.id != undefined && index == this.logisticsAmountAllTable.length - 1) {
          this.timer = setInterval(() => {
            if (document.readyState === 'complete') {
              setTimeout(() => {
                this.goAuchor(this.$route.query.id);
                let newIndex = this.$route.query.id.substr(28, this.$route.query.id.length)
                this.catalogueIndex = Number(newIndex + 3)
                window.clearInterval(this.timer);
              }, 1000)
            }
          }, 1000);
        }
      })
      this.catalogueList.push({id: 'sendToEurope', name: '日本以外へ発送'})
      this.getChildrenHeigh();
    });
  },
  destroyed() {
    this.ContentHeightList = null;
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    goAuchor(id) {
      let ids = '#' + id;
      document.querySelector(ids).scrollIntoView(true)
      let auchor = this.$el.querySelector(ids)
      window.scrollTo({
        top: auchor.offsetTop - 100, //举例：用户点击 第二个标签后 页面就会滚动到第二个标签的高度
        behavior: "smooth" //丝滑滚动
      });
    },
    // 获取页面中每一栏内容的高度数组
    getChildrenHeigh() {
      setTimeout(() => {
        // 获得他们的父元素节点
        let arr = [];
        // console.log(this.navLists.length);
        // 将所有子元素的高度放入arr
        for (let i = 0; i < this.catalogueList.length; i++) {
          // 把所有获得到的子元素高度都放入arr中
          let ids = '#' + this.catalogueList[i].id;
          document.querySelector(ids).scrollIntoView(true)
          let auchor = this.$el.querySelector(ids)
          arr.push(auchor.offsetTop - 100);
        }
        // 给这个arr兜底，这样可以解决最后一个内容栏监听不到的bug，注意：如果最后一栏内容高度低于滚动条当前的高度，也不会高亮显示最后一栏的标题哦
        this.ContentHeightList = arr;
        if (this.$route.query.id == undefined) {
          document.documentElement.scrollTop = 0;
        }
      }, 2000)
    },
    // 监听滚轮
    handleScroll() {
      // 获得当前的滚轮高度
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let Heights = this.ContentHeightList;
      // 只有ContentHeightList存在才监听当前高度
      if (this.ContentHeightList) {
        for (let i = 0; i < Heights.length; i++) {
          // 如果滚轮高度大于当前所在的子元素高度 并且 滚轮高度小于下一个子元素的高度 ，那么说明滚轮在当前内容中，就激活当前的nav栏
          if (scrollTop >= Heights[i] - 200) {
            // console.log(i);
            // 那么就激活当前的nav栏
            this.catalogueIndex = i;
            // return false;
          }
        }
      }
    },
    //体积计算
    volumeCalculate() {
      this.boxList.forEach((item) => {
        if (item.long != 0 && item.width != 0 && item.height != 0) {
          let long = item.long / 100;
          let width = item.width / 100;
          let height = item.height / 100;
          item.stere = (long * width * height).toFixed(2);
          item.newStere = item.long * item.width * item.height;
        }
      })
    },
    //判断长、宽、高、重量是否为空
    isNull() {
      this.boxList.forEach((item) => {
        if (item.long != 0 && item.width != 0 && item.height != 0) {
          this.volumeCalculate();
          if (item.long != 0 && item.width != 0 && item.height != 0 && item.kg != 0) {
            this.volumeCalculate();
            this.getLogisticsAmountAll();
          }
        }
      })
    },
    addBox() {
      this.boxList.push({
        kg: 0,
        long: 58,
        width: 51,
        height: 50,
        stere: 0,
        newStere: 0
      })
    },
    deleteBox(index) {
      this.boxList.splice(index, 1);
    },
    //获取价格计算结果
    getLogisticsAmountAll() {
      let arr = [];
      this.boxList.forEach((item) => {
        if (item.kg != 0 && item.newStere != 0) {
          arr.push({
            weight: item.kg,
            volume: item.newStere,
            length: item.long,
            width: item.width,
            height: item.height
          })
        }
      })
      this.$api.getLogisticsAmountAll({list: arr}).then((res) => {
        this.logisticsAmountAllTable = [];
        this.logisticsAmountAllTable = res.data;
      });
    }
  }
}
</script>
<style scoped lang="scss">
.tableDataClass /deep/ div > table {
  border: 2px solid #C40622;
  margin-bottom: 20px;
  width: 1400px;
  max-width: 1400px;

  tr > td:first-child {
    min-width: 120px;
    max-width: 120px;
    height: 70px;
    background: #B4272B;
    line-height: 70px;
    text-align: center;
    color: #FEFEFE;
    font-weight: bold;
    border-bottom: 1px solid #DFDFDF;
  }

  tr:first-child > td {
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
  }

  tr:last-child > td {
    border-right: 1px solid #DFDFDF;
  }

  tr > td {
    min-width: 127px;
    max-width: 127px;
    height: 70px;
    background: #fff;
    line-height: 70px;
    text-align: center;
    color: #333333;
    border-bottom: 1px solid #DFDFDF;
  }
}
</style>
<style lang="scss" scoped>
.hideOperationContainer {
  width: 60px;
  height: 26px;
  background: linear-gradient(180deg, #F04D52, #B4272B);
  border-radius: 0 4px 4px 0;
  position: fixed;
  top: 24%;
  left: 0;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

header {
  background: #fff;

  .headerBanner {
    background: url("../../assets/internationalLogisticsBanner.jpg") no-repeat;
    height: 350px;
    width: 100%;
    background-size: 100% 100%;

    .headerBannerText {
      text-align: center;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 48px;
      text-shadow: 0 0 27px rgba(0, 0, 0, 0.4);
      padding-top: 129px;
      letter-spacing: 20px;
      margin-bottom: 50px;
    }

    .headerBannerBottomText {
      font-size: 20px;
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      line-height: 30px;
      text-align: center;
      color: #fff;
    }
  }

  .logisticsAcceptancePeriod {
    background: #fff;
    padding: 80px 0;

    .logisticsAcceptancePeriodTableContainer {
      width: 900px;
      margin: 0 auto;

      .logisticsAcceptancePeriodTableHeader {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 56px;
        width: 900px;
        background: #B4272B;

        div {
          text-align: center;
          line-height: 56px;
          color: #fff;
        }

        div:first-child {
          width: 300px;
        }

        div:last-child {
          width: 600px;
        }
      }

      .logisticsAcceptancePeriodTableRow {
        height: 70px;
        width: 900px;
        border-bottom: 1px solid #DFDFDF;

        div {
          text-align: center;
          line-height: 70px;
          color: #333333;
        }

        div:first-child {
          width: 300px;
        }

        div:last-child {
          width: 600px;
        }
      }

      .logisticsAcceptancePeriodTableRow:nth-child(odd) {
        background: #fff;
      }

      .logisticsAcceptancePeriodTableRow:nth-child(even) {
        background: #F6F6F6;
      }
    }

    .leftCatalogueContainer {
      position: fixed;
      left: 0;
      top: 28%;
      width: 170px;
      border-radius: 10px;
      background: #fff;
      padding: 20px;
      box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.1);
      z-index: 99999999999;

      .showChildrenBtn {
        width: 130px;
        height: 30px;
        background: #F0F0F0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        cursor: pointer;
        margin-bottom: 10px;

        .el-icon-arrow-right:before, .el-icon-arrow-down:before {
          font-weight: bold;
          font-size: 14px;
        }

        div {
          color: #333333;
          font-size: 14px;
        }
      }

      .catalogueContainer {
        width: 130px;
        height: 36px;
        border-radius: 4px;
        color: #333333;
        font-size: 14px;
        line-height: 36px;
        text-align: left;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 12px;
      }

      .checkedCatalogue {
        color: #fff;
        background: #B4272B;
      }
    }
  }

  .logisticsAcceptancePeriodTitle {
    color: #333333;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 50px;
    text-align: center;
  }

  .internationalLogisticsFreightEstimationContainer {
    background: #F6F6F6;
    padding: 80px 0;

    .inputListContainer {
      width: 1066px;
      margin: 0 auto;
      justify-content: space-between;

      .symbol {
        margin: 0 10px;
      }

      .inputContainer {
        width: 180px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 4px;
        display: flex;
        align-items: center;

        /deep/ .el-input__inner {
          width: 120px;
          height: 48px;
          max-width: 120px;
          border: none;
        }

        /deep/ .is-disabled > .el-input__inner {
          background: #fff;
        }

        /deep/ .el-input {
          width: 120px;
        }

        .inputText {
          padding: 15px 0;
          height: 20px;
          max-height: 20px;
          width: 60px;
          border-left: 1px solid #DFDFDF;
          text-align: center;
          line-height: 5px;
          color: #333333;
          font-weight: bold;
        }
      }
    }

    .boxListContainer {
      width: 1200px;
      margin: 0 auto;
      padding: 24px;
      box-sizing: border-box;
      background: #fff;

      .No {
        width: 60px;
      }

      .kg {
        width: 197px;
        display: flex;
        align-items: center;
      }

      .btn {
        width: 100px;
        height: 50px;
        background: #50C136;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        color: #FFFFFF !important;
        font-weight: 400 !important;
      }

      .boxListHeader {
        display: flex;
        align-items: center;
        height: 50px;
        border-radius: 4px;
        background: #F6F6F6;
        padding-left: 9px;

        div {
          line-height: 50px;
          color: #333333;
          font-weight: bold;
          font-size: 16px;
        }
      }

      .boxListFooter {
        margin-top: 24px;
      }

      .boxInfoContainer {
        display: flex;
        align-items: center;
        margin-bottom: 18px;

        .No {
          width: 50px;
          height: 50px;
          background: #F6F6F6;
          border-radius: 4px;
          line-height: 50px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          margin-right: 22px;
        }

        .inputContainer {
          width: 170px;
          height: 50px;
          background: #FFFFFF;
          border: 1px solid #DFDFDF;
          border-radius: 4px;
          display: flex;
          align-items: center;

          /deep/ .el-input__inner {
            width: 120px;
            height: 48px;
            max-width: 120px;
            border: none;
          }

          /deep/ .is-disabled > .el-input__inner {
            background: #fff;
          }

          /deep/ .el-input {
            width: 120px;
          }

          .inputText {
            padding: 15px 0;
            height: 20px;
            max-height: 20px;
            width: 60px;
            border-left: 1px solid #DFDFDF;
            text-align: center;
            line-height: 5px;
            color: #333333;
            font-weight: bold;
          }
        }

        .symbol {
          margin-left: 9px;
          color: #333333;
          font-size: 16px;
        }
      }
    }

    .internationalLogisticsFreightListContainer {
      width: 1200px;
      margin: 50px auto 0;
      display: flex;
      flex-wrap: wrap;

      .internationalLogisticsFreightContainer {
        width: 279px;
        height: 299px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        margin-right: 27px;
        margin-bottom: 27px;

        img {
          width: 108px;
          height: 82px;
          margin-bottom: 30px;
        }

        .internationalLogisticsName {
          color: #333333;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 21px;
        }

        .internationalLogisticsKg {
          margin-bottom: 10px;
          color: #B4272B;
        }
      }

      .internationalLogisticsFreightContainer:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

section {
  background: #fff;

  .logisticsAcceptancePeriodTitle {
    color: #333333;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 50px;
    text-align: center;
  }

  .internationalLogisticsSimpleComparisonContainer {
    padding: 80px 0;
    width: 1550px;
    margin: 0 auto;

    .internationalLogisticsSimpleComparisonTableHeader {
      color: #fff;
      width: 1410px;
      margin-left: 140px;

      .left {
        width: 640px;
        height: 112px;
        background: linear-gradient(0deg, #C64B42, #E76E65);
        border-radius: 10px 0 0 0;
      }

      .right {
        width: 768px;
        height: 112px;
        background: linear-gradient(0deg, #5774CC, #678AF3);
        border-radius: 0 10px 0 0;
      }

      .left, .right {
        .flexAndCenter {
          div {
            height: 70px;
            width: 180px;
            text-align: center;
            line-height: 70px;
          }
        }
      }

      .leftHeaderText, .rightHeaderText {
        padding-top: 19px;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        letter-spacing: 20px
      }
    }

    .internationalLogisticsSimpleComparisonTableFooter {
      width: 140px;

      .leftContainer {

        div {
          width: 140px;
          height: 71px;
          background: #F0F0F0;
          border-bottom: 1px solid #DFDFDF;
          color: #333333;
          font-weight: bold;
          font-size: 14px;
          text-align: center;
          line-height: 71px;
        }

        div:first-child {
          border-radius: 10px 0 0 0;
        }
      }

      .rightContainer {
        .flexAndCenter {
          border-bottom: 1px solid #DFDFDF;

          div {
            width: 128px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            color: #333333;
            font-size: 14px;
          }
        }

        .remark {
          div {
            line-height: 35px;
            height: 140px;
          }
        }

        .flexAndCenter:nth-child(odd) {
          background: #fff !important;
        }

        .flexAndCenter:nth-child(even) {
          background: #F6F6F6 !important;
        }
      }
    }

    .internationalLogisticsSimpleComparisonRedText {
      color: rgb(230, 0, 0);
      margin: 40px 0 29px;
    }

    .newTitle {
      color: #333333;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 17px;
    }
  }
}

footer {
  padding-top: 160px;
  background: #fff;

  .logisticsAcceptancePeriodTitle {
    color: #333333;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 108px;
  }

  .internationalLogisticsFooterContainer {
    width: 1400px;
    margin: 0 auto;

    .internationalLogisticsMannerContainer {
      border-top: 1px solid #DFDFDF;
      padding-bottom: 80px;

      .internationalLogisticsMannerContainerTop {
        margin-top: 8px;
        justify-content: space-between;

        div {
          color: #DFDFDF;
          font-weight: bold;
        }

        div:last-child {
          font-style: italic;
        }
      }

      .internationalLogisticsName {
        margin: 50px 0 30px;
        color: #333333;
        font-weight: bold;
        font-size: 24px;
      }

      .mattersNeedAttentionTopText {
        .flexAndCenter div {
          color: #B4272B;
          font-weight: bold;
          font-size: 16px;
        }

        .flexAndCenter div:first-child {
          margin-right: 53px;
        }
      }

      .mattersNeedAttentionTitleText {
        margin-bottom: 17px;
        color: #333333;
        font-weight: bold;
        font-size: 18px;
      }

      .mattersNeedAttentionExplainText {
        line-height: 24px;
        color: #333333;
      }
    }
  }
}

#sendToEurope, #sendToUSA {
  padding: 80px 0;
  box-sizing: border-box;
  background: #fff;

  .sendToEuropeList {
    display: flex;
    flex-direction: column;
    width: 800px;
    margin: 0 auto;
  }

  .sendToEuropeTitle {
    color: #333333;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 48px;
    text-align: center;
  }

  .internationalLogisticsSimpleComparisonTableHeader {
    color: #fff;
    width: 660px;
    margin-left: 140px;

    .left {
      width: 330px;
      height: 80px;
      background: linear-gradient(0deg, #C64B42, #E76E65);
      border-radius: 10px 0 0 0;
    }

    .right {
      width: 330px;
      height: 80px;
      background: linear-gradient(0deg, #5774CC, #678AF3);
      border-radius: 0 10px 0 0;
    }

    .left, .right {
      .flexAndCenter {
        div {
          height: 70px;
          width: 180px;
          text-align: center;
          line-height: 70px;
        }
      }
    }

    .leftHeaderText, .rightHeaderText {
      padding-top: 19px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      letter-spacing: 20px
    }
  }

  .internationalLogisticsSimpleComparisonTableFooter {
    width: 800px;

    .leftContainer {

      div {
        width: 140px;
        height: 71px;
        background: #F0F0F0;
        border-bottom: 1px solid #DFDFDF;
        color: #333333;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        line-height: 71px;
      }

      div:first-child {
        border-radius: 10px 0 0 0;
      }
    }

    .rightContainer {
      .flexAndCenter {
        border-bottom: 1px solid #DFDFDF;

        div {
          width: 330px;
          height: 70px;
          line-height: 70px;
          text-align: center;
          color: #333333;
          font-size: 14px;
        }
      }

      .remark {
        div {
          line-height: 35px;
          height: 140px;
        }
      }

      .flexAndCenter:nth-child(odd) {
        background: #fff !important;
      }

      .flexAndCenter:nth-child(even) {
        background: #F6F6F6 !important;
      }
    }
  }

  .sendToEuropeUpdateDate {
    margin-top: 9px;
    color: #B4272B;
    font-weight: bold;
    margin-bottom: 28px;
    text-align: right;
  }

}
</style>
